import ConfigApi from '../../configs/ConfigApi';
import store from '../store';
import AxiosAuth from '../utils/AxiosAuth';

function initSetup(payload) {
    return {
        type: 'INIT_SET',
        payload,
    };
}

function configInit(payload) {
    return {
        type: 'CONFIG_SET',
        payload,
    };
}

function activeMenuSet(payload) {
    return {
        type: 'ACTIVE_MENU_SET',
        payload,
    };
}

export default function AppInitDispatch(dispatch, params) {
    return new Promise((resolve, reject) => {
        if (!store.getState().initData?.initId || params?.reset === true) {
            AxiosAuth.post(ConfigApi.API_APP_INIT, params)
                .then((response) => {
                    // console.log(response.data.categoryHomeIds);
                    // Init Info
                    localStorage.setItem('init-id', response.data.initData.initId);
                    dispatch(initSetup(response.data.initData));
                    dispatch(configInit(response.data.configs));
                    dispatch(activeMenuSet('/'));

                    resolve(true);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        } else {
            resolve(true);
        }
    });
}
