import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import iconDashboard from '../../../../assets/images/icon/icon-dashboard-3.svg';
import iconHome from '../../../../assets/images/icon/icon-home-3.svg';
import iconProfile from '../../../../assets/images/icon/icon-profile-3.svg';
import iconSubscription from '../../../../assets/images/icon/icon-subscription-3.svg';
import iconSupport from '../../../../assets/images/icon/icon-support-3.svg';
import BrowserInfo from '../../../utils/BrowserInfo';
import MobileFooterModal from '../../../utils/MobileFooterModal';
import SidebarMenuSection from './SidebarMenuSection';

function MobileFooter() {
    const { sizeName } = BrowserInfo();
    const [showServiceOption, setShowServiceOption] = useState(false);

    if (['xs', 'sm', 'md', 'lg'].indexOf(sizeName) === -1) {
        return null;
    }

    return (
        <>
            {/* <div className="admin-menu-mobile-gap" /> */}
            <MobileFooterModal show={showServiceOption} setShow={setShowServiceOption}>
                <div className="footer-menu-area">
                    <SidebarMenuSection
                        hideProfile
                        clickOnEndItem={() => {
                            setShowServiceOption(false);
                        }}
                    />
                </div>
            </MobileFooterModal>
            <div className="admin-menu-mobile">
                <div className="menu-area">
                    <div>
                        <Link to="/">
                            <img src={iconHome} alt="Home" />
                            <span>Home</span>
                        </Link>
                    </div>
                    <div>
                        <Link
                            to="/"
                            onClick={(ev) => {
                                ev.preventDefault();
                                setShowServiceOption(true);
                            }}
                        >
                            <img src={iconSubscription} alt="Subscription" />
                            <span>Subscription</span>
                        </Link>
                    </div>
                    <div className="footer-dashboard-option">
                        <Link to="/user/dashboard/">
                            <img src={iconDashboard} alt="Dashboard" />
                            <span>Dashboard</span>
                        </Link>
                    </div>
                    <div>
                        <Link to="/user/support/">
                            <img src={iconSupport} alt="Support" />

                            <span>Support</span>
                        </Link>
                    </div>
                    <div>
                        <Link to="/user/support/">
                            <img src={iconProfile} alt="Profile" />
                            <span>Profile</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileFooter;
