import React, { useEffect, useRef } from 'react';

function LeftSideModal({ children, show, setShow }) {
    const ref = useRef();
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShow]);

    if (!show) {
        return null;
    }

    return (
        <div className="left-modal">
            <div className="left-options" ref={ref}>
                {children}
            </div>
        </div>
    );
}

export default LeftSideModal;
