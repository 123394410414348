import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import iconAppHosting from '../../../../assets/images/icon/icon-app-hosting.svg';
import iconArrowRight from '../../../../assets/images/icon/icon-arrow-right.svg';
import iconCloudServer from '../../../../assets/images/icon/icon-cloud-server.svg';
import iconDashboard from '../../../../assets/images/icon/icon-dashboard.svg';
import iconDedicatedServer from '../../../../assets/images/icon/icon-dedicated-server.svg';
import iconDomain from '../../../../assets/images/icon/icon-domain.svg';
import iconEmail from '../../../../assets/images/icon/icon-email.svg';
import iconHosting from '../../../../assets/images/icon/icon-hosting.svg';
import iconMailHosting from '../../../../assets/images/icon/icon-mail-hosting.svg';
import iconServer from '../../../../assets/images/icon/icon-server.svg';
import iconSms from '../../../../assets/images/icon/icon-sms.svg';
import iconSoftware from '../../../../assets/images/icon/icon-software.svg';
import iconVps from '../../../../assets/images/icon/icon-vps.svg';
import iconWebHosting from '../../../../assets/images/icon/icon-web-hosting.svg';
import imgAvatar from '../../../../assets/images/img-avatar.jpg';

const list = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        path: '/user/dashboard/',
        icon: iconDashboard,
    },
    {
        id: 'domain',
        title: 'Domain',
        path: '/user/services/domain/',
        icon: iconDomain,
    },
    {
        id: 'hosting',
        title: 'Hosting',
        path: '/user/services/hosting/',
        icon: iconHosting,
        subMenu: [
            {
                id: 'hosting_web',
                title: 'Web Hosting',
                path: '/user/services/hosting/web/',
                icon: iconWebHosting,
            },
            {
                id: 'hosting_app',
                title: 'App Hosting',
                path: '/user/services/hosting/app/',
                icon: iconAppHosting,
            },
            {
                id: 'hosting_mail',
                title: 'Mail Hosting',
                path: '/user/services/hosting/mail/',
                icon: iconMailHosting,
            },
        ],
    },
    {
        id: 'server',
        title: 'Server',
        path: '/user/services/server/',
        icon: iconServer,
        subMenu: [
            {
                id: 'server_cloud',
                title: 'Cloud Server',
                path: '/user/services/server/cloud/',
                icon: iconCloudServer,
            },
            {
                id: 'server_vps',
                title: 'VPS',
                path: '/user/services/server/vps/',
                icon: iconVps,
            },
            {
                id: 'server_dedicated',
                title: 'Dedicated Server',
                path: '/user/services/server/dedicated/',
                icon: iconDedicatedServer,
            },
        ],
    },
    {
        id: 'sms-package',
        title: 'SMS Package',
        path: '/user/services/sms-package/',
        icon: iconSms,
    },
    {
        id: 'mailbox',
        title: 'Mailbox',
        path: '/user/services/mailbox/',
        icon: iconEmail,
    },
    {
        id: 'software',
        title: 'Software',
        path: '/user/services/software/',
        icon: iconSoftware,
    },
];

const ProfileInfo = ({ show }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="profile-info">
            <img src={imgAvatar} alt="Avatar" />
            <div className="profile-name">Kumar Bishojit Paul</div>
            <div className="profile-contact">bishojit@gmail.com</div>
            <div className="profile-button">
                <button type="button" className="button button-gr-read">
                    Edit Profile
                </button>
            </div>
        </div>
    );
};

const OptionSubList = ({ clickOnEndItem, subMenu, openL1, setOpenL1 }) => {
    // console.log(subMenu);

    if (!subMenu.length) {
        return null;
    }

    return (
        <ul className="label-1">
            {subMenu.map((item) => (
                <li key={item.id} className={openL1 === item.id ? 'open' : ''}>
                    <Link
                        to={item.path}
                        onClick={(ev) => {
                            setOpenL1(item.id);

                            if (typeof clickOnEndItem === 'function') clickOnEndItem(ev);
                        }}
                    >
                        <img className="icon" src={item.icon} alt={item.title} />
                        <span>{item.title}</span>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const OptionList = ({ removeMenus = [], clickOnEndItem, menuL0, menuL1 }) => {
    const [openL0, setOpenL0] = useState(menuL0);
    const [openL1, setOpenL1] = useState(menuL1);

    return (
        <div className="option-list">
            <ul>
                {list
                    .filter((item) => removeMenus.indexOf(item.id) === -1)
                    .map((item) => {
                        if (item.subMenu?.length) {
                            return (
                                <li key={item.id} className={openL0 === item.id ? 'open' : ''}>
                                    <a
                                        href={item.path}
                                        onClick={(ev) => {
                                            ev.preventDefault();

                                            setOpenL0(openL0 === item.id ? null : item.id);
                                        }}
                                    >
                                        <img className="icon" src={item.icon} alt={item.title} />
                                        <span>{item.title}</span>

                                        {item.subMenu?.length ? (
                                            <img className="arrow" src={iconArrowRight} alt="" />
                                        ) : null}
                                    </a>

                                    {item.subMenu?.length ? (
                                        <OptionSubList
                                            subMenu={item.subMenu}
                                            openL1={openL1}
                                            setOpenL1={setOpenL1}
                                            clickOnEndItem={clickOnEndItem}
                                        />
                                    ) : null}
                                </li>
                            );
                        }
                        return (
                            <li key={item.id} className={openL0 === item.id ? 'open' : ''}>
                                <Link
                                    to={item.path}
                                    onClick={(ev) => {
                                        setOpenL0(item.id);

                                        if (typeof clickOnEndItem === 'function')
                                            clickOnEndItem(ev);
                                    }}
                                >
                                    <img className="icon" src={item.icon} alt={item.title} />
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

function SidebarMenuSection({ hideProfile, clickOnEndItem }) {
    const removeMenus = [];
    if (hideProfile) {
        removeMenus.push('dashboard');
    }

    return (
        <div className="profile-section">
            <ProfileInfo show={!hideProfile} />

            {hideProfile ? null : <div className="divider" />}

            <OptionList
                menuL0="dashboard"
                menuL1="server_vps"
                removeMenus={removeMenus}
                clickOnEndItem={clickOnEndItem}
            />
        </div>
    );
}

export default SidebarMenuSection;
