import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { useAuth } from '../../../contexts/AuthProvider';
import AxiosAuth from '../../../utils/AxiosAuth';
import HostingLargeListSection from '../components/HostingLargeListSection';
import ServerLargeListSection from '../components/ServerLargeListSection';

function UsersHostingPage() {
    const { hostingType } = useParams();
    const { currentUser } = useAuth();
    const [hostingData, setHostingData] = useState([]);

    useEffect(() => {
        AxiosAuth.currentUserAuth(currentUser)
            .get(ConfigApi.API_HOSTING_LIST.replace(':type', hostingType))
            .then(({ data }) => {
                if (data.error === 0) {
                    setHostingData(data.services);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentUser, hostingType]);

    if (hostingType === 'web') {
        return <HostingLargeListSection hostingData={hostingData} />;
    }
    if (hostingType === 'app') {
        return null;
    }
    if (hostingType === 'mail') {
        return null;
    }
    return <ServerLargeListSection />;
}

export default UsersHostingPage;
