/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import btnClose from '../../../../assets/images/btn-close.svg';
import iconFacebookW from '../../../../assets/images/icon/icon-facebook-w.svg';
import iconGithubW from '../../../../assets/images/icon/icon-github-w.svg';
import iconGoogleW from '../../../../assets/images/icon/icon-google-w.svg';
import { useAuth } from '../../../contexts/AuthProvider';

function LoginModal({ show, handleCloseClick }) {
    const refUsername = useRef();
    const refPassword = useRef();

    const { signUp, signInWithGoogle, signInWithFacebook, signInWithGithub } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleLogin(ev) {
        ev.preventDefault();
        // console.log(ev, refUsername.current.value, refPassword.current.value);

        try {
            setError('');
            setLoading(true);
            await signUp(refUsername.current.value, refPassword.current.value);
            // history.push('/');
        } catch {
            setError('Failed to register');
        }
    }

    // console.log(error, loading);

    async function handelGoogleClick() {
        try {
            setError('');
            setLoading(true);
            await signInWithGoogle();
            // history.push('/');
        } catch (e) {
            console.log(e);
            setError('Failed to Google Sign In');
        }
    }

    if (show !== true) {
        return null;
    }

    return (
        <div className="modal-box backdrop">
            <div className="modal-box-content">
                <div className="modal-box-header">
                    <div>
                        <button
                            className="button squire modal-box-close"
                            type="button"
                            onClick={handleCloseClick}
                        >
                            <img src={btnClose} alt="Close" />
                        </button>
                    </div>
                </div>
                <div className="modal-box-body">
                    <div>
                        <div className="modal-box-title">User Login</div>

                        <div className="login-input">
                            <div className="line line-g2" style={{ marginBottom: '12px' }}>
                                <div className="cell cell-6">
                                    <div className="label">Email Address</div>
                                    <input
                                        type="text"
                                        name=""
                                        className="form-input d-block w-100"
                                        placeholder="ex: username@email.com"
                                        ref={refUsername}
                                    />
                                </div>
                                <div className="cell cell-6">
                                    <div className="label">Password</div>
                                    <input
                                        type="password"
                                        name=""
                                        className="form-input d-block w-100"
                                        placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                                        ref={refPassword}
                                    />
                                </div>
                            </div>

                            <div className="forget-pass" style={{ marginBottom: '27px' }}>
                                <a href="/">Forgot Password?</a>
                            </div>

                            <div className="mb-3">
                                <button
                                    className="button button-gr-pink w-100"
                                    type="button"
                                    onClick={handleLogin}
                                >
                                    Sign In
                                </button>
                            </div>

                            <div className="text-center text-g mb-3">OR</div>

                            <div className="line line-g2" style={{ marginBottom: '30px' }}>
                                <div className="cell cell-fill">
                                    <button
                                        className="button button-gr-orange w-100 button-login-op"
                                        type="button"
                                        onClick={() =>
                                            signInWithGoogle()
                                                .then((user) => {
                                                    // handleRedirectToOrBack();
                                                    console.log(user);
                                                })
                                                .catch((e) => console.log(e.message))
                                        }
                                    >
                                        <img src={iconGoogleW} alt="Google" />
                                        Google
                                    </button>
                                </div>

                                <div className="cell cell-fill">
                                    <button
                                        className="button button-gr-blue w-100 button-login-op"
                                        type="button"
                                        onClick={() =>
                                            signInWithFacebook()
                                                .then((user) => {
                                                    // handleRedirectToOrBack();
                                                    console.log(user);
                                                })
                                                .catch((e) => console.log(e.message))
                                        }
                                    >
                                        <img src={iconFacebookW} alt="Google" />
                                        Facebook
                                    </button>
                                </div>
                                <div className="cell cell-fill">
                                    <button
                                        className="button button-gr-dark-blue w-100 button-login-op"
                                        type="button"
                                        onClick={() =>
                                            signInWithGithub()
                                                .then((user) => {
                                                    // handleRedirectToOrBack();
                                                    console.log(user);
                                                })
                                                .catch((e) => console.log(e.message))
                                        }
                                    >
                                        <img src={iconGithubW} alt="Google" />
                                        Github
                                    </button>
                                </div>
                            </div>

                            <div className="text-center">
                                <button
                                    className="button d-inline-block w-auto button-transparent"
                                    type="button"
                                >
                                    <span
                                        className="text-g"
                                        style={{
                                            color: '#130F40',
                                            opacity: '0.5',
                                        }}
                                    >
                                        Don&apos;t have an account?
                                    </span>
                                    &nbsp;
                                    <span className="text-g">Sign Up</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-box-footer">
                    <div />
                </div>
            </div>
        </div>
    );
}

export default LoginModal;
