import React from 'react';
import iconCloudServer from '../../../../assets/images/icon/icon-cloud-server-round.svg';
import iconIot from '../../../../assets/images/icon/icon-iot-round.svg';
import iconItPlanning from '../../../../assets/images/icon/icon-it-planning-round.svg';
import iconSoftDev from '../../../../assets/images/icon/icon-software-development-round.svg';

function WelcomeSection() {
    return (
        <div className="welcome-section">
            <div className="size-adjust" />

            <div className="document">
                <div className="container position-relative h-100">
                    <div className="welcome-text-area">
                        <h1 className="welcome-title">Let&apos;s Speed Up!</h1>
                        <p className="welcome-body mb-6">
                            BIKIRAN.COM provides better technology, to speed up your business. We
                            helps you to automated your daily task. Our automation tools/software
                            energized your business.
                        </p>
                        <div>
                            <button type="button" className="button button-learn-more">
                                Learn More
                            </button>
                            <button type="button" className="button button-explore-service ml-4">
                                Explore Service
                            </button>
                        </div>
                    </div>

                    <div className="welcome-features-area">
                        <div className="line line-g3">
                            <div className="cell cell-3">
                                <div className="welcome-features">
                                    <img src={iconCloudServer} alt="Cloud Server" />
                                    <span>
                                        Server & Cloud
                                        <br />
                                        Service
                                    </span>
                                </div>
                            </div>

                            <div className="cell cell-3">
                                <div className="welcome-features">
                                    <img src={iconItPlanning} alt="IT Planning and Consultancy" />
                                    <span>
                                        IT Planning
                                        <br />
                                        and Consultancy
                                    </span>
                                </div>
                            </div>

                            <div className="cell cell-3">
                                <div className="welcome-features">
                                    <img src={iconSoftDev} alt="Software Development" />
                                    <span>
                                        Software
                                        <br />
                                        Development
                                    </span>
                                </div>
                            </div>

                            <div className="cell cell-3">
                                <div className="welcome-features">
                                    <img src={iconIot} alt="IoT In House" />
                                    <span>
                                        IoT
                                        <br />
                                        In House
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WelcomeSection;
