const api = process.env.REACT_APP_API_URL;

const ConfigApi = {
    API_APP_INIT: `${api}/api/init/`,
    API_LOGIN: `${api}/bikiran/auth/firebase-login/`,

    API_HOSTING_LIST: `${api}/bikiran/services/hosting/:type/`,
};

export default ConfigApi;
