import React from 'react';
import iconAppHosting from '../../../../assets/images/icon/icon-app-hosting-round.svg';
import iconArrowRight from '../../../../assets/images/icon/icon-arrow-right.svg';
import iconCloudVm from '../../../../assets/images/icon/icon-cloud-vm-round.svg';
import iconDedicatedServer from '../../../../assets/images/icon/icon-dedicated-server-round.svg';
import iconEcomSoft from '../../../../assets/images/icon/icon-ecom-soft-round.svg';
import iconEduSoft from '../../../../assets/images/icon/icon-edu-soft-round.svg';
import iconEmailHosting from '../../../../assets/images/icon/icon-email-hosting-round.svg';
import iconEmailService from '../../../../assets/images/icon/icon-email-service-round.svg';
import iconNewsPortal from '../../../../assets/images/icon/icon-news-portal-round.svg';
import iconPushNotification from '../../../../assets/images/icon/icon-push-notification-round.svg';
import iconSmsService from '../../../../assets/images/icon/icon-sms-service-round.svg';
import iconVps from '../../../../assets/images/icon/icon-vps-round.svg';
import iconWebHosting from '../../../../assets/images/icon/icon-web-hosting-round.svg';

const serviceOptions = [
    {
        id: 11,
        image: iconWebHosting,
        title: 'Web Hosting',
        description:
            'If you runs a small website or application with low tariff, then we are ready to provide quality',
        url: '/',
    },
    {
        id: 12,
        image: iconAppHosting,
        title: 'App Hosting',
        description:
            'If you runs a small or medium application, then we are ready to provide quality-full &...',
        url: '/',
    },
    {
        id: 13,
        image: iconEmailHosting,
        title: 'Email Hosting',
        description:
            'If you need some business emails and website for your organization. We provides...',
        url: '/',
    },

    {
        id: 21,
        image: iconCloudVm,
        title: 'Cloud VM',
        description:
            'If you runs a medium or high tariff website then we provide best managed/unmanaged..',
        url: '/',
    },
    {
        id: 22,
        image: iconVps,
        title: 'VPS',
        description:
            'If you runs a medium or high tariff website then we provide best managed/unmanaged VPS.',
        url: '/',
    },
    {
        id: 23,
        image: iconDedicatedServer,
        title: 'Dedicated Server',
        description:
            'If you required email server or file server, audio/video strumming server, data ...',
        url: '/',
    },

    {
        id: 31,
        image: iconEcomSoft,
        title: 'E-Commerce Software',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/',
    },
    {
        id: 32,
        image: iconEduSoft,
        title: 'Education Software',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/',
    },
    {
        id: 33,
        image: iconNewsPortal,
        title: 'News Portal Software',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/',
    },

    {
        id: 41,
        image: iconSmsService,
        title: 'SMS Service',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/',
    },
    {
        id: 42,
        image: iconEmailService,
        title: 'Email Service',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/',
    },
    {
        id: 43,
        image: iconPushNotification,
        title: 'Push Notification',
        description:
            'If you runs website with low tariff, then we provide quality-full Hosting Service.',
        url: '/',
    },
];

function ServicesSection() {
    return (
        <div className="services-area">
            <div className="container">
                <div className="services-area-header">
                    <h3 className="sub-title">Our Service</h3>
                    <h1 className="title">Choose Your need</h1>
                    <p>
                        We runes few services for your business growth. You may find out your best
                        choice. Our service is scalable, secure and smooth. And we are ready to
                        support you.
                    </p>
                </div>

                <div className="service-description-area">
                    <div className="line">
                        {serviceOptions.map((item) => (
                            <div className="cell cell-4" key={item.id}>
                                <div className="service-option">
                                    <img src={item.image} alt={item.title} />
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>

                                    <button className="button button-transparent" type="button">
                                        Start Conversation
                                        <img src={iconArrowRight} alt="Arrow Right" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesSection;
