import React from 'react';
import iconSend from '../../../../assets/images/icon/icon-send-round.svg';

function MapsSection() {
    return (
        <div className="map-section" style={{}}>
            <div className="map-area">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.12012329859087!2d90.37317941208579!3d23.821373510327955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c15a756274ff%3A0xa989049bb339b1a0!2sBIKIRAN.COM!5e0!3m2!1sen!2sbd!4v1636968169775!5m2!1sen!2sbd"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Bikiran Maps"
                />
            </div>

            <div className="email-subscribe">
                <span>Want to get Discount & Product Updates from us?</span>

                <div className="text-input">
                    <input type="text" placeholder="Enter your email address" />
                    <button type="button">
                        <img src={iconSend} alt="Send" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MapsSection;
