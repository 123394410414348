import React from 'react';
import HeaderSection from '../../users/components/HeaderSection';
import FixedHeaderControl from '../components/FixedHeaderControl';
import FooterSection from '../components/FooterSection';
import MapsSection from '../components/MapsSection';
import ServicesSection from '../components/ServicesSection';
import WelcomeSection from '../components/WelcomeSection';

function IndexPage() {
    return (
        <>
            <FixedHeaderControl>
                <HeaderSection />
            </FixedHeaderControl>

            <WelcomeSection />

            <ServicesSection />

            <MapsSection />

            <FooterSection />
        </>
    );
}

export default IndexPage;
