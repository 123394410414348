/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import imgHr from '../../../../assets/images/bg-hr.svg';
import iconAboutUs from '../../../../assets/images/icon/icon-about-us.svg';
import iconBack from '../../../../assets/images/icon/icon-arrow-left.svg';
import iconClients from '../../../../assets/images/icon/icon-clients.svg';
import iconDashboard from '../../../../assets/images/icon/icon-dashboard-1.svg';
import iconHome from '../../../../assets/images/icon/icon-home.svg';
import iconMenu from '../../../../assets/images/icon/icon-menu.svg';
import iconProfile from '../../../../assets/images/icon/icon-my-profile-1.svg';
import iconServices from '../../../../assets/images/icon/icon-services.svg';
import iconSignOut from '../../../../assets/images/icon/icon-sign-out-1.svg';
import iconSupport from '../../../../assets/images/icon/icon-support-1.svg';
import iconBilling from '../../../../assets/images/icon/icon-wallet-1.svg';
import imgAvatar from '../../../../assets/images/img-avatar.jpg';
import { useAuth } from '../../../contexts/AuthProvider';
import LeftSideModal from '../../../utils/LeftSideModal';
import LoginModal from './LoginModal';

const publicMenu = [
    {
        id: 'home',
        title: 'Home',
        url: '/',
        icon: iconHome,
    },
    {
        id: 'services',
        title: 'Services',
        url: '/services/',
        icon: iconServices,
    },
    {
        id: 'clients',
        title: 'Clients',
        url: '/clients/',
        icon: iconClients,
    },
    {
        id: 'about',
        title: 'About',
        url: '/about/',
        icon: iconAboutUs,
    },
];

const privateMenu = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        url: '/user/dashboard/',
        icon: iconDashboard,
    },
    {
        id: 'myProfile',
        title: 'My Profile',
        url: '/user/my-profile/',
        icon: iconProfile,
    },
    {
        id: 'billing',
        title: 'Billing',
        url: '/billing/',
        icon: iconBilling,
    },
    {
        id: 'support',
        title: 'Support',
        url: '/support/',
        icon: iconSupport,
    },
];

const privateMenu2 = [
    {
        id: 'logOut',
        title: 'Logout',
        url: '/logout/',
        icon: iconSignOut,
    },
];

function UserArea({ currentUser }) {
    const [showLoginModal, setShowLoginModal] = useState(false);

    if (currentUser?.userFound) {
        return (
            <div className="line user-area">
                <div className="cell">
                    <img
                        src={imgAvatar}
                        alt="Avatar"
                        className="avatar-image ml-4 mr-4"
                        onClick={(ev) => {
                            console.log(ev);
                        }}
                        onProgress={(ev) => {
                            console.log(ev);
                        }}
                        tabIndex="-1"
                        role="button"
                    />
                </div>
                <div className="cell">
                    <div className="user-sidebar-info">
                        <h3>Kumar Bishojit Paul</h3>
                        <span>bishojit@gmail.com</span>
                    </div>
                </div>
            </div>
        );
    }

    const handleLoginClick = () => {
        setShowLoginModal(true);
    };

    const handleCloseClick = () => {
        setShowLoginModal(false);
    };

    return (
        <div className="">
            <div className="text-center">
                <button
                    type="button"
                    className="button button-outline-pink"
                    onClick={handleLoginClick}
                >
                    Login
                </button>
            </div>

            <LoginModal show={showLoginModal} handleCloseClick={handleCloseClick} />
        </div>
    );
}

function SideBarModal({ show, setShow, currentUser, signOut }) {
    console.log(currentUser);

    return (
        <LeftSideModal show={show} setShow={setShow}>
            <div className="left-modal-header text-left">
                <img
                    src={iconBack}
                    alt="Back"
                    onClick={() => {
                        setShow(false);
                    }}
                    role="button"
                />
            </div>
            <div className="left-modal-body text-left">
                <UserArea currentUser={currentUser} />

                <img src={imgHr} alt="HR" className="hr" />

                <ul className="menu-list">
                    {publicMenu.map((item) => (
                        <li key={item.id}>
                            <Link
                                to={item.url}
                                onClick={() => {
                                    setShow(false);
                                }}
                            >
                                <img src={item.icon} alt="" />
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    ))}
                </ul>

                {currentUser?.userFound ? (
                    <>
                        <img src={imgHr} alt="HR" className="hr" />

                        <ul className="menu-list">
                            {privateMenu.map((item) => (
                                <li key={item.id}>
                                    <Link
                                        to={item.url}
                                        onClick={() => {
                                            setShow(false);
                                        }}
                                    >
                                        <img src={item.icon} alt="" />
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <img src={imgHr} alt="HR" className="hr" />

                        <ul className="menu-list">
                            {privateMenu2.map((item) => (
                                <li key={item.id}>
                                    <Link
                                        to={item.url}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setShow(false);
                                            signOut();
                                        }}
                                    >
                                        <img src={item.icon} alt="" />
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}
            </div>
        </LeftSideModal>
    );
}

function MobileSidebarMenu() {
    const { currentUser, signOut } = useAuth();
    const [show, setShow] = useState(false);

    return (
        <div className="text-sm-right">
            <span className="menu-button">
                <img
                    src={iconMenu}
                    alt="Menu Icon"
                    onClick={() => {
                        setShow(true);
                    }}
                    role="button"
                />
            </span>

            <SideBarModal
                show={show}
                setShow={setShow}
                currentUser={currentUser}
                signOut={signOut}
            />
        </div>
    );
}

export default MobileSidebarMenu;
