import React from 'react';
import iconContactMail from '../../../../assets/images/icon/icon-contacts-mail.svg';
import iconContactPhone from '../../../../assets/images/icon/icon-contacts-phone.svg';
import iconFb from '../../../../assets/images/icon/icon-facebook-link.svg';
import iconGh from '../../../../assets/images/icon/icon-github-link.svg';
import iconLi from '../../../../assets/images/icon/icon-linkedin-link.svg';
import iconSk from '../../../../assets/images/icon/icon-skype-link.svg';
import iconYt from '../../../../assets/images/icon/icon-youtube-link.svg';
import imgPaymentGateway from '../../../../assets/images/payment-gateway.jpg';

function FooterSection() {
    return (
        <div className="footer-section">
            <div>
                <div className="footer-area">
                    <div className="container">
                        <div className="line">
                            <div className="cell cell-5 cell-xl-12 footer-separator">
                                <div className="line">
                                    <div className="cell-12 cell-xl-6 cell-sm-12">
                                        <div className="footer-title">Address</div>
                                        <p className="footer-details">
                                            House-02, Block-F, Shangbadik Abashik Elaka, Kalshi
                                            Road, Mirpur-11, Dhaka-1216, Bangladesh
                                        </p>
                                    </div>
                                    <div className="cell-12 cell-xl-6 cell-sm-12">
                                        <div className="footer-title">Contact</div>
                                        <div className="footer-contacts line">
                                            <div className="cell cell-6 cell-xxl-12 contacts">
                                                <a href="tel:+8801925363333">
                                                    <img
                                                        src={iconContactPhone}
                                                        alt="Contact Phone"
                                                    />
                                                    <span>+880 1925 363333</span>
                                                </a>
                                            </div>

                                            <div className="cell cell-6 cell-xxl-12 contacts">
                                                <a
                                                    href="mailto:bishojit@gmail.com"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={iconContactMail} alt="Contact Mail" />
                                                    <span>bishojit@gmail.com</span>
                                                </a>
                                            </div>

                                            <div className="cell cell-6 cell-xxl-12 contacts">
                                                <a href="tel:+8801717416667">
                                                    <img
                                                        src={iconContactPhone}
                                                        alt="Contact Phone"
                                                    />
                                                    <span>+880 1717 416667</span>
                                                </a>
                                            </div>

                                            <div className="cell cell-6 cell-xxl-12 contacts">
                                                <a
                                                    href="mailto:info@bikiran.com"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={iconContactMail} alt="Contact Mail" />
                                                    <span>info@bikiran.com</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-7 cell-xl-12">
                                <div className="line">
                                    <div className="cell cell-4 cell-sm-12 mb-3">
                                        <div className="footer-title">Company</div>
                                        <div className="footer-details">
                                            <ul>
                                                <li>
                                                    <a href="/">
                                                        <span>About Us</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <span>Services</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <span>Our Team</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <span>Contact Us</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="cell cell-4 cell-sm-12 mb-3">
                                        <div className="footer-title">Important Links</div>
                                        <div className="footer-details">
                                            <ul>
                                                <li>
                                                    <a href="/">
                                                        <span>Terms & Condition</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <span>Affiliate</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <span>User Sign Up</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <span>Our Pricing Plans</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="cell cell-4 cell-sm-12 mb-3">
                                        <div className="footer-title">Find us on Social</div>
                                        <div className="footer-details">
                                            <ul>
                                                <li>
                                                    <a href="/">
                                                        <img src={iconFb} alt="" />
                                                        <span>Facebook</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <img src={iconSk} alt="" />
                                                        <span>Skype</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <img src={iconYt} alt="" />
                                                        <span>Youtube</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <img src={iconLi} alt="" />
                                                        <span>Linkedin</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">
                                                        <img src={iconGh} alt="" />
                                                        <span>Github</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container footer-mini">
                <div className="line">
                    <div className="cell cell-lg-12 footer-mini-text">
                        Copy © 2021 All Rights Reserved By BIKIRAN.COM
                    </div>
                    <div className="cell cell-fill cell-lg-12">
                        <div className="text-right h-70px footer-mini-image">
                            <img
                                src={imgPaymentGateway}
                                alt="Payment Gateway"
                                className="footer-payment-gateway"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterSection;
