/* eslint-disable no-unused-vars */
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GithubAuthProvider,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    // eslint-disable-next-line prettier/prettier
    signOut
} from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import ConfigApi from '../../configs/ConfigApi';
import { firebaseAuth } from '../MyFirebase';
import AxiosAuth from '../utils/AxiosAuth';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

function verifyBikiranLogin() {
    return new Promise((resolve, reject) => {
        // --Call to Bikiran API and generate Temp Token
    });
}

export default function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    function signUp(email, password) {
        // console.log(firebaseAuth, email, password);
        return createUserWithEmailAndPassword(firebaseAuth, email, password);
    }

    function signIn(email, password) {
        // console.log(firebaseAuth, email, password);
        return signInWithEmailAndPassword(firebaseAuth, email, password);
    }

    function logout() {
        return signOut(firebaseAuth);
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(firebaseAuth, email);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email);
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password);
    }

    function signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('profile');
        provider.addScope('email');
        provider.addScope('openid');

        return signInWithPopup(firebaseAuth, provider);
    }
    function signInWithFacebook() {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
        provider.addScope('email');
        return signInWithPopup(firebaseAuth, provider);
    }
    function signInWithGithub() {
        const provider = new GithubAuthProvider();
        return signInWithPopup(firebaseAuth, provider);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            const providerData = user?.providerData || [];

            if (providerData.length > 0) {
                AxiosAuth.post(ConfigApi.API_LOGIN, {
                    uid: user.uid,
                    refreshToken: user.refreshToken,
                })
                    .then(({ data }) => {
                        setCurrentUser({
                            userFound: true,
                            provider: providerData[0].providerId,
                            nickName: providerData[0].displayName,
                            email: providerData[0].email,
                            phoneNumber: providerData[0].phoneNumber,
                            uid: providerData[0].uid,
                            localProvider: user.providerId,
                            localUid: user.uid,
                            photoUrl: data.user.photo_url,
                            displayName: data.user.name,
                            refreshToken: user.refreshToken,
                        });
                    })
                    .catch((error) => {
                        setCurrentUser({
                            userFound: false,
                            provider: '',
                            localProvider: '',
                            displayName: '',
                            nickName: '',
                            email: '',
                            phoneNumber: '',
                            photoUrl: '',
                            uid: '',
                            localUid: '',
                            refreshToken: '',
                        });
                    });
            } else {
                setCurrentUser({
                    userFound: false,
                    provider: '',
                    localProvider: '',
                    displayName: '',
                    nickName: '',
                    email: '',
                    phoneNumber: '',
                    photoUrl: '',
                    uid: '',
                    localUid: '',
                    refreshToken: '',
                });
            }

            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        signUp,
        signIn,
        signOut: logout,
        resetPassword,
        updateEmail,
        updatePassword,
        signInWithGoogle,
        signInWithFacebook,
        signInWithGithub,
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
