import React, { useEffect, useState } from 'react';
import BrowserInfo from './BrowserInfo';

function DebugInfoOperation() {
    const [browserInfo, setBrowserInfo] = useState(BrowserInfo());

    useEffect(() => {
        const handleResize = () => {
            setBrowserInfo(BrowserInfo());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="debug-info">
            <div className="line info">
                <div className="cell cell-6">Resolution:</div>
                <div className="cell cell-6">
                    {browserInfo.screenWidth}x{browserInfo.screenHeight}
                </div>
            </div>
            <div className="line info">
                <div className="cell cell-6">Size Name:</div>
                <div className="cell cell-6">{browserInfo.sizeName}</div>
            </div>
        </div>
    );
}

function DebugInfo({ show = true }) {
    if (!show) {
        return false;
    }

    if (process.env.NODE_ENV === 'development') {
        return <DebugInfoOperation />;
    }

    return null;
}

export default DebugInfo;
