import React from 'react';

const CloudServerArea = () => {
    console.log(2);
    return (
        <div className="product-info mb-6">
            <div>
                <div className="line">
                    <div className="cell cell-6">
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Host Name:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">bikiran.com</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">IPs:</span>
                            </div>
                            <div className="cell cell-7">
                                <div className="value">
                                    <span>192.112.011.003 (Primary)</span>
                                    <br />
                                    <span>192.112.011.004</span>
                                    <br />
                                    <span>192.112.011.005</span>
                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Package:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">Cloud - 10 GB SSD</span>
                                <br />
                                <span className="value">CPU: 1 Core & RAM: 2 GB</span>
                            </div>
                        </div>
                    </div>
                    <div className="cell cell-6">
                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Expired on:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">30 - July - 2020</span>
                                <br />
                                <span className="value-info">194 Days Remain</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Registered on:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">30 - July - 2019</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5">
                                <span className="subject">Renew Price:</span>
                            </div>
                            <div className="cell cell-7">
                                <span className="value">USD 12.99</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button type="button" className="button button-outline-pink">
                        Show More
                    </button>
                </div>
            </div>
        </div>
    );
};

const ServerArea = () => {
    console.log(1);

    return (
        <div className="content-area">
            <div className="content-head">
                <div className="line line-g1 mb-3 ml-6 mr-6 mt-4">
                    <div className="cell">
                        <h1 className="content-title">Domain</h1>
                    </div>
                    <div className="cell">
                        <input
                            type="text"
                            name=""
                            placeholder="Domain Name"
                            className="form-input"
                        />
                    </div>
                    <div className="cell">
                        <select name="" className="form-input">
                            <option>Status</option>
                            <option>Active</option>
                            <option>Not Active</option>
                            <option>Expired</option>
                            <option>Expired soon</option>
                            <option>Recovery Mode</option>
                        </select>
                    </div>
                    <div className="cell-fill">&nbsp;</div>
                    <div>
                        <button type="button" className="button button-gr-green">
                            Buy Domain
                        </button>
                    </div>
                </div>
            </div>

            <div className="content-body">
                <div className="content-body-scroll">
                    <CloudServerArea />
                    <CloudServerArea />
                </div>
            </div>
        </div>
    );
};

function ServerLargeListSection() {
    return <ServerArea />;
}

export default ServerLargeListSection;
