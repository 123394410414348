/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserAuthHandler from './app/users/models/UserAuthHandler';
import UsersDashboardPage from './app/users/pages/UsersDashboardPage';
import UsersDomainPage from './app/users/pages/UsersDomainPage';
import UsersHostingPage from './app/users/pages/UsersHostingPage';
import UsersMailboxPage from './app/users/pages/UsersMailboxPage';
// import UsersPage from './app/users/pages/UsersPage';
import UsersServerPage from './app/users/pages/UsersServerPage';
import UsersSmsPage from './app/users/pages/UsersSmsPage';
import UsersSoftwarePage from './app/users/pages/UsersSoftwarePage';
import AboutPage from './app/websites/pages/AboutPage';
import AffiliatePage from './app/websites/pages/AffiliatePage';
import ClientsPage from './app/websites/pages/ClientsPage';
import ContactUsPage from './app/websites/pages/ContactUsPage';
import DevelopersPage from './app/websites/pages/DevelopersPage';
import IndexPage from './app/websites/pages/IndexPage';
import OurTeamPage from './app/websites/pages/OurTeamPage';
import PricingPage from './app/websites/pages/PricingPage';
import ServicesPage from './app/websites/pages/ServicesPage';
import TermsConditionPage from './app/websites/pages/TermsConditionPage';
import AppInitDispatch from './dispatches/AppInitDispatch';
import DebugInfo from './utils/DebugInfo';
import InitInfo from './utils/InitInfo';
// element={<UsersPage />}

function RouteHandler() {
    const dispatch = useDispatch();
    const { initData } = InitInfo();
    const [show, setShow] = useState(!!initData.initId);

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    // if (!show) {
    //     return <EmptyPlaceholder />;
    // }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/services/" element={<ServicesPage />} />
                    <Route path="/our-clients/" element={<ClientsPage />} />
                    <Route path="/about-us/" element={<AboutPage />} />
                    <Route path="/affiliate-page/" element={<AffiliatePage />} />
                    <Route path="/developers/" element={<DevelopersPage />} />
                    <Route path="/our-team/" element={<OurTeamPage />} />
                    <Route path="/pricing/" element={<PricingPage />} />
                    <Route path="/terms-condition/" element={<TermsConditionPage />} />
                    <Route path="/contact-us/" element={<ContactUsPage />} />

                    <Route path="/user/*" element={<UserAuthHandler />}>
                        <Route path="dashboard/" element={<UsersDashboardPage />} />
                        <Route path="services/domain/" element={<UsersDomainPage />} />
                        <Route
                            path="services/hosting/:hostingType/"
                            element={<UsersHostingPage />}
                        />
                        <Route path="services/server/:serverType/" element={<UsersServerPage />} />
                        <Route path="services/sms-package/" element={<UsersSmsPage />} />
                        <Route path="services/mailbox/" element={<UsersMailboxPage />} />
                        <Route path="services/software/" element={<UsersSoftwarePage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
            <DebugInfo show={false} />
        </>
    );
}
export default RouteHandler;
