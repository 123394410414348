/* eslint-disable no-unused-vars */
import React from 'react';
import { Provider } from 'react-redux';
import '../assets/css/bikiran.css';
import '../assets/css/bikiran.responsive.css';
import '../assets/css/style.admin.css';
import '../assets/css/style.admin.responsive.css';
import '../assets/css/style.web.css';
import '../assets/css/style.web.responsive.css';
import AuthProvider from './contexts/AuthProvider';
import RouteHandler from './RouteHandler';
import store from './store';

function App() {
    return (
        <AuthProvider>
            <Provider store={store}>
                <RouteHandler />
            </Provider>
        </AuthProvider>
    );
}

export default App;
