const screenSizeList = [
    320, // 100% - max:425px - xs
    576, // 540px  - sm
    768, // 720px - md
    992, // 960px  - lg
    1200, // 1140px - xl
    1400, // 1340px - xxl
    1600, // 1540px - 3xl
    2560, // 1540px - 4xl
];

const getSize = () => {
    const width = window.innerWidth;

    if (width < 320) {
        return 'xs';
    }

    if (width < 576) {
        return 'sm';
    }

    if (width < 768) {
        return 'md';
    }

    if (width < 992) {
        return 'lg';
    }

    if (width < 1200) {
        return 'xl';
    }

    if (width < 1400) {
        return 'xxl';
    }

    if (width < 1600) {
        return '3xl';
    }

    if (width < 2560) {
        return '4xl';
    }

    return '5xl';
};

// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1400px

export default function BrowserInfo() {
    return {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        sizeName: getSize(),
        screenSizeList,
    };
}
