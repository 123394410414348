import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import bikiranBanner from '../../../../assets/images/banner-bikiran.svg';
import iconCart from '../../../../assets/images/icon/icon-cart.svg';
import iconDashboard from '../../../../assets/images/icon/icon-dashboard.svg';
import iconMyProfile from '../../../../assets/images/icon/icon-my-profile.svg';
import iconSignOut from '../../../../assets/images/icon/icon-sign-out.svg';
import iconSupport from '../../../../assets/images/icon/icon-support.svg';
import iconWallet from '../../../../assets/images/icon/icon-wallet.svg';
import imgAvatar from '../../../../assets/images/img-avatar.jpg';
import { useAuth } from '../../../contexts/AuthProvider';
import InstOption from '../../../utils/InstOption';
import LoginModal from './LoginModal';
import MobileSidebarMenu from './MobileSidebarMenu';

const menu = [
    {
        id: 'home',
        title: 'Home',
        path: '/',
    },
    {
        id: 'services',
        title: 'Service',
        path: '/services/',
    },
    {
        id: 'clients',
        title: 'Clients',
        path: '/clients/',
    },
    {
        id: 'about',
        title: 'About',
        path: '/about/',
    },
];

const LoginArea = ({ currentUser, showLoginPopup }) => {
    const [show, setShow] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    const { signOut } = useAuth();

    const handleLoginClick = () => {
        setShow(true);
    };

    const handleCloseClick = () => {
        setShow(false);
    };

    if (!currentUser?.userFound) {
        return (
            <div className="line line-end line-align-item-center">
                <div className="cell">
                    <img src={iconCart} alt="Cart" />
                </div>
                <div className="cell cell-1" />
                <div className="cell">
                    <button
                        type="button"
                        className="button button-outline-pink"
                        onClick={handleLoginClick}
                    >
                        Login
                    </button>
                </div>

                <LoginModal show={show || showLoginPopup} handleCloseClick={handleCloseClick} />
            </div>
        );
    }

    return (
        <div className="position-relative">
            <div className="line line-end line-no-wrap line-align-item-center">
                <div className="cell">
                    <img src={iconCart} alt="Cart" />
                </div>
                <div className="cell cell-1" />
                <div className="cell">
                    <div
                        className="line line-no-wrap line-align-item-center"
                        onClick={() => {
                            setShowOptions(true);
                        }}
                        role="button"
                        tabIndex="-1"
                    >
                        <div className="cell">
                            <span className="avatar-text">{currentUser.displayName}</span>
                        </div>
                        <div className="cell">
                            <img
                                className="avatar-image"
                                src={currentUser.photoUrl || imgAvatar}
                                alt="Avatar"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <InstOption show={showOptions} setShow={setShowOptions}>
                <Link to="/user/dashboard/">
                    <img src={iconDashboard} alt="Dashboard" /> <span>Dashboard</span>
                </Link>
                <Link to="/">
                    <img src={iconMyProfile} alt="My Profile" /> <span>My Profile</span>
                </Link>
                <Link to="/">
                    <img src={iconWallet} alt="Billing" /> <span>Billing</span>
                </Link>
                <Link to="/">
                    <img src={iconSupport} alt="Support" /> <span>Support</span>
                </Link>
                <div className="divider" style={{ margin: '7px' }} clickable="off" />
                <Link
                    to="/"
                    onClick={(ev) => {
                        ev.preventDefault();
                        signOut();
                    }}
                >
                    <img src={iconSignOut} alt="Sign Out" /> <span>Logout</span>
                </Link>
            </InstOption>
        </div>
    );
};

function HeaderSection({ showLoginPopup }) {
    const { currentUser } = useAuth();

    return (
        <div className="header header-section">
            <div className="container">
                <div className="line line-align-item-center">
                    <div className="cell cell-profile">
                        <Link to="/">
                            <img
                                className="banner-image"
                                src={bikiranBanner}
                                alt="Bikiran Banner"
                            />
                        </Link>
                    </div>

                    <div className="cell cell-fill">
                        <ul className="menu-top menu-top-area">
                            {menu.map((item) => (
                                <li key={item.id}>
                                    <Link to={item.path}>
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        <MobileSidebarMenu />
                    </div>

                    <div className="cell cell-3 d-sm-none">
                        <div className="position-relative">
                            <LoginArea currentUser={currentUser} showLoginPopup={showLoginPopup} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderSection;
