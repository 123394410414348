/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

const WebHostingArea = ({ item }) => {
    const { nameservers } = item;

    return (
        <div className="product-info mb-6">
            <div>
                <div className="line">
                    <div className="cell cell-6 cell-md-12 cell-sm-12">
                        <div className="line mb-3">
                            <div className="cell cell-5 cell-sm-12">
                                <span className="subject">Primary Domain:</span>
                            </div>
                            <div className="cell cell-7 cell-sm-12">
                                <span className="value">{item.domain}</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5 cell-sm-12">
                                <span className="subject">Name Server:</span>
                            </div>
                            <div className="cell cell-7 cell-sm-12">
                                <div className="value">
                                    {nameservers.filter((i) => i).length > 0
                                        ? nameservers.map((nameServer) => (
                                              <div>{nameServer || 'Not Set'}</div>
                                          ))
                                        : 'Not Set'}
                                </div>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5 cell-sm-12">
                                <span className="subject">IP Address:</span>
                            </div>
                            <div className="cell cell-7 cell-sm-12">
                                <span className="value">{item.ipAddress}</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5 cell-sm-12">
                                <span className="subject">Package:</span>
                            </div>
                            <div className="cell cell-7 cell-sm-12">
                                <span className="value">WEB - 10 GB SSD</span>
                            </div>
                        </div>
                    </div>
                    <div className="cell cell-6 cell-md-12">
                        <div className="line mb-3">
                            <div className="cell cell-5 cell-sm-12">
                                <span className="subject">Expired on:</span>
                            </div>
                            {item.expiredDays > 0 ? (
                                <div className="cell cell-7 cell-sm-12">
                                    <span className="value">{item.expiredOn}</span>
                                    <br />
                                    <span className="value-info text-red">
                                        {item.expiredDays} Days Remain
                                    </span>
                                </div>
                            ) : (
                                <div className="cell cell-7 cell-sm-12">
                                    <span className="value" style={{ color: 'red' }}>
                                        {item.expiredOn} (Expired)
                                    </span>
                                    <br />
                                    <span className="value-info">
                                        {item.expiredDays * -1} Days Over
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5 cell-sm-12">
                                <span className="subject">Registered on:</span>
                            </div>
                            <div className="cell cell-7 cell-sm-12">
                                <span className="value">{item.createdOn}</span>
                            </div>
                        </div>

                        <div className="line mb-3">
                            <div className="cell cell-5 cell-sm-12">
                                <span className="subject">Renew Price:</span>
                            </div>
                            <div className="cell cell-7 cell-sm-12">
                                <span className="value">
                                    {item.currency} {item.renewPrice}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button type="button" className="button button-outline-pink">
                        Show More
                    </button>
                </div>
            </div>
        </div>
    );
};

const AppHostingArea = () => (
    <div className="product-info mb-6">
        <div>
            <div className="line">
                <div className="cell cell-6 cell-md-12">
                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Primary Domain:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">bikiran.com</span>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Name Server:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <div className="value">
                                <span>ns1.bikiran.com</span>
                                <br />
                                <span>ns2.bikiran.com</span>
                                <br />
                            </div>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">IP Address:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">120.120.120.120 (Shared)</span>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Package:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">APP - 10 GB SSD</span>
                            <br />
                            <span className="value">CPU: 2 Core & RAM: 4 GB</span>
                            <br />
                        </div>
                    </div>
                </div>
                <div className="cell cell-6 cell-md-12">
                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Expired on:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">30 - July - 2020</span>
                            <br />
                            <span className="value-info">194 Days Remain</span>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Registered on:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">30 - July - 2019</span>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Renew Price:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">USD 12.99</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button type="button" className="button button-outline-pink">
                    Show More
                </button>
            </div>
        </div>
    </div>
);

const MailHostingArea = () => (
    <div className="product-info mb-6">
        <div>
            <div className="line">
                <div className="cell cell-6">
                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Primary Domain:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">bikiran.com</span>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Name Server:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <div className="value">
                                <span>ns1.bikiran.com</span>
                                <br />
                                <span>ns2.bikiran.com</span>
                                <br />
                            </div>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Package:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">Mail - 10 GB/Account</span>
                            <br />
                            <span className="value">30 Account</span>
                            <br />
                        </div>
                    </div>
                </div>
                <div className="cell cell-6">
                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Expired on:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">30 - July - 2020</span>
                            <br />
                            <span className="value-info">194 Days Remain</span>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Registered on:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">30 - July - 2019</span>
                        </div>
                    </div>

                    <div className="line mb-3">
                        <div className="cell cell-5 cell-sm-12">
                            <span className="subject">Renew Price:</span>
                        </div>
                        <div className="cell cell-7 cell-sm-12">
                            <span className="value">USD 12.99</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button type="button" className="button button-outline-pink">
                    Show More
                </button>
            </div>
        </div>
    </div>
);

const HostingArea = ({ hostingData }) => {
    const [domain, setDomain] = useState('');
    const [status, setStatus] = useState('active');

    const handelDomainSearch = (ev) => {
        setDomain(ev.target.value);
    };

    const handelStatusChange = (ev) => {
        setStatus(ev.target.value);
    };

    return (
        <div className="content-area">
            <div className="content-head">
                <div className="line line-g1 mb-3 ml-6 mr-6 mt-4">
                    <div className="cell">
                        <h1 className="content-title">Web Hosting (Shared)</h1>
                    </div>
                    <div className="cell cell-2">
                        <input
                            type="text"
                            name=""
                            placeholder="Domain Name"
                            className="form-input w-100"
                            onChange={handelDomainSearch}
                        />
                    </div>
                    <div className="cell cell-2">
                        <select
                            name=""
                            className="form-input"
                            onChange={handelStatusChange}
                            onBlur={handelStatusChange}
                            value={status}
                        >
                            <option value="">Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Not Active</option>
                            <option value="expired">Expired</option>
                            <option value="expire-soon">Expire soon</option>
                        </select>
                    </div>
                    <div className="cell-fill">&nbsp;</div>
                    <div>
                        <button type="button" className="button button-gr-green">
                            Buy Hosting
                        </button>
                    </div>
                </div>
            </div>

            <div className="content-body">
                <div className="content-body-scroll">
                    {hostingData
                        .filter((item) => {
                            if (domain && item.domain.search(domain) === -1) {
                                return false;
                            }

                            if (status && item.status.indexOf(status) === -1) {
                                return false;
                            }

                            return true;
                        })
                        .map((item) => (
                            <WebHostingArea item={item} key={item.id} />
                        ))}
                </div>
            </div>
        </div>
    );
};

function HostingLargeListSection({ hostingData }) {
    // console.log(hostingData);
    return <HostingArea hostingData={hostingData} />;
}

export default HostingLargeListSection;
