import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider';
import BrowserInfo from '../../../utils/BrowserInfo';
import HeaderSection from '../components/HeaderSection';
import LoginModal from '../components/LoginModal';
import MobileFooter from '../components/MobileFooter';
import SidebarMenuSection from '../components/SidebarMenuSection';

function UserAuthHandler() {
    const { currentUser } = useAuth();
    const browserInfo = BrowserInfo();
    // const location = useLocation();

    // console.log(currentUser);

    if (!currentUser) {
        return <div />;
    }

    if (currentUser?.userFound !== true) {
        return (
            <div>
                <HeaderSection showLoginPopup />
            </div>
        );
    }

    return (
        <div className={['h-100', 'browser', `browser-${browserInfo.sizeName}`].join(' ')}>
            <HeaderSection />

            <LoginModal />

            <div className="body-section">
                <div className="container h-100">
                    <div className="line line-g3 line-sm-g0 line-no-wrap h-100">
                        <div className="cell cell-profile h-100 sidebar-menu-area">
                            <SidebarMenuSection />
                        </div>
                        <div className="cell cell-content h-100">
                            <div className="content-section">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MobileFooter />
        </div>
    );
}

export default UserAuthHandler;
