import store from '../store';
import BrowserInfo from './BrowserInfo';

function InitInfo() {
    const storeStates = store.getState();

    return {
        storeStates,
        initData: storeStates?.initData,

        browserInfo: BrowserInfo,
    };
}

export default InitInfo;
